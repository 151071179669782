import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { getWallets } from "../../../api/routes";
import WalletItem from "../wallet/WalletItem";
import "./wallets.scss";

const Wallets = () => {
  const [industria, setIndustria] = useState(localStorage.getItem("industria"));
  const [filteredWallets, setFilteredWallets] = useState([]);

  window.addEventListener("industria", () => {
    setIndustria(localStorage.getItem("industria"));
  });

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  function fetchWallets() {
    return axios.get(getWallets, config);
  }

  const { data, isFetching, isFetched } = useQuery("wallets", fetchWallets);

  isFetching && <h2>Loading...</h2>;

  data?.data?.length <= 0 && <h2>Nenhuma carteira cadastrada!</h2>;

  function filterWallets() {
    if (industria === "-1") {
      setFilteredWallets(data?.data);
      return;
    }
    setFilteredWallets(
      data?.data?.filter(
        (wallet) => parseInt(industria) === parseInt(wallet.id_industria)
      )
    );
  }

  useEffect(() => {
    filterWallets();
  }, []);

  useEffect(() => {
    filterWallets();
  }, [industria, isFetched]);

  return (
    <div className="walletsWrapper">
      {isFetched && filteredWallets?.length > 0 ? (
        filteredWallets.map((wallet) => (
          <WalletItem wallet={wallet} key={wallet.id} />
        ))
      ) : (
        <h2>Nenhuma carteira encontrada!</h2>
      )}
    </div>
  );
};

export default Wallets;
