import React, { useEffect, useState } from "react";
import "./ticket.scss";
import TicketResolve from "../ticket_resolve/TicketResolve";
import { formatDate } from "../../../util/FormatDate";
import { industria } from "../../../api/routes";
//axios
import axios from "axios";
import { useQuery } from "react-query";

const Ticket = ({ ticketInfo, ticketType }) => {
  const [ticketShowResponse, setTicketShowResponse] = useState(false);

  const type = ticketInfo.tipo;

  const typesContent = [
    {
      type: "duvida",
      btn: "Responder",
      label: "Email:",
      labelDesc: ticketInfo.email,
    },
    {
      type: "problema",
      btn: "Resolvido",
      label: "Local do problema:",
      labelDesc: ticketInfo.onde,
    },
  ];

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  const fetchIndustria = () => {
    return axios.get(`${industria}/${ticketInfo.id_industria}`, config);
  };

  const indReturn = useQuery(
    ["industria", ticketInfo.id_industria],
    fetchIndustria
  );

  const changeShowReponseHandler = () => {
    localStorage.setItem("responseShow", ticketInfo.id);
    window.dispatchEvent(new Event("responseShowChange"));
  };

  const verifyIfActiveHandler = () => {
    if (ticketInfo.id.toString() === localStorage.getItem("responseShow")) {
      setTicketShowResponse(true);
    } else {
      setTicketShowResponse(false);
    }
  };

  window.addEventListener("responseShowChange", () => {
    verifyIfActiveHandler();
  });

  useEffect(() => {
    verifyIfActiveHandler();
  }, []);

  return (
    <>
      <div className={`ticket-card-wrapper ${type !== ticketType && "hidden"}`}>
        <div className="card-top">
          <h4>Indústria:</h4>
          <p>{indReturn.data?.data.nome}</p>
          <div className="card-top-inner">
            <div className="id-ticket">
              <strong>
                <p>#</p>
              </strong>
              <p>{ticketInfo.id}</p>
            </div>
            <div className="ticket-type">
              <strong>
                <p> {typesContent.find((e) => e.type === type).label}</p>
              </strong>
              <p>{typesContent.find((e) => e.type === type).labelDesc}</p>
            </div>
            <div className="ticket-type">
              <strong>
                <p> Criado em:</p>
              </strong>
              <p>{formatDate(ticketInfo.data_criacao)}</p>
            </div>
          </div>
        </div>
        <div className="card-bottom">
          <div className="ticket-description">
            <strong>
              <p className="ticket-description-title">Descrição:</p>
            </strong>
            <p className="ticket-text">{ticketInfo.descricao}</p>
          </div>
          <button onClick={changeShowReponseHandler} className="ticket-btn">
            {typesContent.find((e) => e.type === type).btn}
          </button>
        </div>
      </div>
      {ticketShowResponse && <TicketResolve ticketInfo={ticketInfo} />}
    </>
  );
};

export default Ticket;
