import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router";
import MainContentWrapper from "../contentWrapper/MainContentWrapper";
import "./wallet.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ethers } from "ethers";

const Wallet = () => {
  const BUTTONS_OPTIONS = { 1: "create", 2: "wallets" };
  const [btnSelect, setBtnSelect] = useState(BUTTONS_OPTIONS[2]);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const [walletBalance, setWalletBalance] = useState("");
  const [myWallet, setMyWallet] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/wallet/${btnSelect}`);
  }, [btnSelect]);

  provider.getBalance(myWallet).then((balance) => {
    setWalletBalance(ethers.utils.formatEther(balance));
  });

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          setMyWallet(result[0]);
        });
      // .catch((error) => {
      //   alert("Voce deve possuir o metamask instalado!");
      // });
    }
  };

  useEffect(() => {
    connectWallet();
  });

  return (
    <MainContentWrapper>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <nav className="nav-ticket">
        <div className="buttons-top-ticket-wrapper">
          <button
            onClick={() => setBtnSelect(BUTTONS_OPTIONS[1])}
            className={`button-ticket-top ${
              btnSelect === BUTTONS_OPTIONS[1] && "button-ticket-active"
            }`}
          >
            Criar
          </button>
          <button
            onClick={() => setBtnSelect(BUTTONS_OPTIONS[2])}
            className={`button-ticket-top ${
              btnSelect === BUTTONS_OPTIONS[2] && "button-ticket-active"
            }`}
          >
            Carteiras
          </button>
        </div>
      </nav>
      <h3>Valor em sua carteira: {walletBalance} VLX</h3>
      <Outlet />
    </MainContentWrapper>
  );
};

export default Wallet;
