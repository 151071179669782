import React, { useState } from "react";
import "../../scss/index.scss";

const MainContentWrapper = ({ children }) => {
  const [industria, setIndustria] = useState(
    localStorage.getItem("industriaNome") || ""
  );

  window.addEventListener("industria", () => {
    setIndustria(localStorage.getItem("industriaNome"));
  });

  return (
    <>
      <h1
        style={{
          color: "white",
          textAlign: "start",
          fontSize: "1rem",
        }}
      >
        Você está na indústria {industria}
      </h1>
      <div className="main-content-wrapper">{children}</div>
    </>
  );
};

export default MainContentWrapper;
