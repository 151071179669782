import React from "react";
import "./errosLog.scss";

const ErrorLogItem = ({ error }) => {
  return (
    <div className="errorItem">
      <p>{error.date}</p>
      <p>{error.message}</p>
      <p>{error.stack}</p>
    </div>
  );
};

export default ErrorLogItem;
