import React, { useState, useEffect } from "react";
import "./walletItem.scss";
import { ethers } from "ethers";
import ScrollContainer from "react-indiana-drag-scroll";

import { useQuery } from "react-query";
import axios from "axios";
import { industria, getAllPlayers } from "../../../api/routes";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

//alert
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const WalletItem = ({ wallet }) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const [myWallet, setMyWallet] = useState("");
  const [myProvider, setMyProvider] = useState("");
  const [balanceToSend, setBalanceToSend] = useState("");

  const [isShowingWords, setIsShowinWords] = useState(false);
  const [walletPassword, setWalletPassword] = useState("");

  const address = wallet.numero;
  const [walletBalance, setWalletBalance] = useState("");
  const [idIndustriaSelected, setIdIndustriaSelected] = useState(
    localStorage.getItem("industria") || "-1"
  );

  window.addEventListener("industria", () => {
    setIdIndustriaSelected(localStorage.getItem("industria"));
  });

  provider.getBalance(address).then((balance) => {
    setWalletBalance(ethers.utils.formatEther(balance));
  });

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  function fetchIndustrias() {
    return axios.get(industria, config);
  }

  const industrias = useQuery("industrias", fetchIndustrias);

  function fetchPlayers() {
    return axios.get(getAllPlayers, config);
  }

  const players = useQuery("players", () => fetchPlayers());

  function getIndustriaFiltered() {
    return industrias.data?.data?.values.find(
      (ind) => parseInt(ind.id) === parseInt(wallet.id_industria)
    ).nome;
  }

  function getPlayerFiltered() {
    return players.data?.data?.find(
      (player) => parseInt(player.id) === parseInt(wallet.id_player)
    ).nome;
  }

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          setMyWallet(result[0]);
          setMyProvider(new ethers.providers.Web3Provider(window.ethereum));
        });
      // .catch((error) => {
      //   alert("Voce deve possuir o metamask instalado!");
      // });
    }
  };

  // console.log(address);
  function sendBalanceHandler() {
    if (balanceToSend <= 0) {
      toast.warn("Valor deve ser maior que 0!");
      return;
    }
    const tx = {
      from: myWallet,
      to: address,
      value: ethers.utils.parseUnits(balanceToSend, "ether").toHexString(),
    };
    if (myProvider !== "")
      myProvider
        .getSigner()
        .sendTransaction(tx)
        .then(toast.info("Enviado para confirmação na MetaMask!"))
        .catch((err) => {
          console.log(err);
          toast.warn(
            <div>
              Erro ao enviar! <br /> Erro: {err.code}
            </div>
          );
        });
    setBalanceToSend(0);
  }

  useEffect(() => {
    connectWallet();
    const refreshWallet = setInterval(() => {
      provider.getBalance(address).then((balance) => {
        setWalletBalance(ethers.utils.formatEther(balance));
      });
    }, 60000);

    return () => clearInterval(refreshWallet);
  }, []);

  return (
    <div className="walletWrapper">
      <ScrollContainer horizontal hideScrollbars={false} className="container">
        <h2>{wallet.numero}</h2>
      </ScrollContainer>
      {idIndustriaSelected === "-1" && (
        <div className="wallet-item">
          <p>Indústria:</p>
          <p>{getIndustriaFiltered()}</p>
        </div>
      )}
      <div className="wallet-item">
        <p>Player:</p>
        <p>{getPlayerFiltered()}</p>
      </div>
      <div style={{ userSelect: "none" }} className="wallet-item">
        <p>Palavras chave: </p>
        <div>
          {isShowingWords ? (
            <div className="flex space-between align-center">
              {wallet?.palavras_chave}
              <AiFillEyeInvisible
                style={{ cursor: "pointer" }}
                onClick={() => setIsShowinWords(false)}
              />
            </div>
          ) : (
            <div className="flex space-between align-center">
              <input
                type="password"
                autoComplete="new-password"
                placeholder="Digite a senha da conta."
                value={walletPassword}
                onChange={(e) => setWalletPassword(e.target.value)}
              />
              <AiFillEye
                style={{ cursor: "pointer" }}
                onClick={() => setIsShowinWords(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="walletBalance">
        <p>Saldo disponível:</p>
        <p>{walletBalance} VLX</p>
      </div>
      <div className="walletBalance-add">
        <input
          value={balanceToSend}
          onChange={(e) => setBalanceToSend(e.target.value)}
          step="0.01"
          type="number"
          name=""
          id=""
          placeholder="Valor"
          min="0"
        />
        <button onClick={() => sendBalanceHandler()}>Adicionar</button>
      </div>
    </div>
  );
};

export default WalletItem;
