import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaTicketAlt, FaWallet } from "react-icons/fa";
import {
  BsFillFileBarGraphFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { MdError } from "react-icons/md";
import { IoExit } from "react-icons/io5";
import "./navBar.scss";
import Select from "react-select";
//react query
import { industria } from "../../api/routes";
import axios from "axios";
import { useQuery } from "react-query";

const NavBar = () => {
  const [btnActive, setBtnActive] = useState(
    parseInt(localStorage.getItem("btnActiveNavBar")) || 1
  );

  const [optionsValues, setOptionsValues] = useState([]);
  const [selectedIndustria, setSelectedIndustria] = useState(
    localStorage.getItem("industria") || "-1"
  );
  let options = [];

  const changeBtnAcrtiveHandler = (btn) => {
    setBtnActive(btn);
    localStorage.setItem("btnActiveNavBar", btn);
  };

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  const fetchIndustria = () => {
    return axios.get(industria, config);
  };

  const reqIndustrias = useQuery("industrias", fetchIndustria);

  useEffect(() => {
    if (reqIndustrias.isFetched) {
      options.push({ value: -1, label: "Todas" });
      reqIndustrias.data?.data.values.map((ind) =>
        options.push({ value: ind.id, label: ind.nome })
      );
      setOptionsValues(options);
    }
  }, [reqIndustrias.isFetching]);

  const logout = () => {
    localStorage.setItem("logged", false);
    localStorage.setItem("function", "");
    localStorage.setItem("tk", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("industria", -1);
    localStorage.setItem("refreshTokenTime", "");
    localStorage.setItem("refreshLongerTokenTime", "");
    window.dispatchEvent(new Event("storage"));
  };

  const onChangeIndHandler = (e) => {
    localStorage.setItem("industria", e.value);
    localStorage.setItem("industriaNome", e.label);
    setSelectedIndustria(e.value);
    window.dispatchEvent(new Event("industria"));
  };

  return (
    <aside className="navWrapper">
      <nav className="nav">
        <ul className="nav-itens-wrapper">
          <li className="nav-item nav-item-select">
            <div className="nav-select-wrapper">
              <Select
                className="nav-select"
                options={optionsValues}
                onChange={(e) => onChangeIndHandler(e)}
                placeholder="Todas"
                value={optionsValues.filter(
                  (option) => option.value === parseInt(selectedIndustria)
                )}
              />
              <i>
                <BsFillArrowDownCircleFill />
              </i>
            </div>
          </li>
          <li className="nav-item">
            <Link
              to="/ticket"
              className={`nav-link ${btnActive === 1 && "login-btn-active"}`}
              onClick={() => changeBtnAcrtiveHandler(1)}
            >
              <p>Ticket</p>
              <i>
                <FaTicketAlt />
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${btnActive === 2 && "login-btn-active"}`}
              to="/wallet"
              onClick={() => changeBtnAcrtiveHandler(2)}
            >
              <p>Carteira</p>
              <i>
                <FaWallet />
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${btnActive === 3 && "login-btn-active"}`}
              to="/report"
              onClick={() => changeBtnAcrtiveHandler(3)}
            >
              <p>Relatório</p>
              <i>
                <BsFillFileBarGraphFill />
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${btnActive === 4 && "login-btn-active"}`}
              to="/errorsLog"
              onClick={() => changeBtnAcrtiveHandler(4)}
            >
              <p>Erros</p>
              <i>
                <MdError />
              </i>
            </Link>
          </li>
          <li className="nav-item logout">
            <Link className="nav-link " to={"/"} onClick={logout}>
              <p>Logout</p>
              <i>
                <IoExit />
              </i>
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default NavBar;
