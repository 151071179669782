import React, { useState } from "react";
import { IoWallet } from "react-icons/io5";
import { useMutation, useQuery } from "react-query";
import { createWallet } from "../../../api/routes";
import axios from "axios";
import { industria, getAllPlayers } from "../../../api/routes";

import "./walletCreate.scss";

//alert
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const WalletCreate = () => {
  const [password, setPassword] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [walletAdress, setWalletAdress] = useState("");
  const [idIndustria, setIdIndustria] = useState("-1");
  const [idPlayer, setIdPlayer] = useState("-1");

  const notify = () => toast.success("Novo registro de carteira criado!!");
  const notifyError = () => toast.warn("Erro!");

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  function fetchIndustrias() {
    return axios.get(industria, config);
  }

  function fetchPlayers() {
    return axios.get(getAllPlayers, config);
  }

  const industrias = useQuery("industrias", fetchIndustrias);

  const players = useQuery("players", fetchPlayers);

  function clearAllFieldsHandler() {
    setPassword("");
    setKeyWords("");
    setWalletAdress("");
    notify();
  }

  function fetchWallet(data) {
    return axios
      .post(createWallet, data, config)
      .then(clearAllFieldsHandler)
      .catch(notifyError);
  }

  const mutation = useMutation(() =>
    fetchWallet({
      senha: password.toString(),
      palavras_chave: keyWords.toString(),
      numero: walletAdress.toString(),
      id_industria: parseInt(idIndustria),
      id_player: parseInt(idPlayer),
    })
  );

  function createWalletHandler(e) {
    e.preventDefault();
    if (
      idIndustria === "-1" ||
      idPlayer === "-1" ||
      password === "" ||
      keyWords === "" ||
      walletAdress === ""
    ) {
      toast.warn("Todos os campos devem ser preenchidos!");
      return;
    }
    mutation.mutate();
  }

  industrias.isFetching && <h2>Loading...</h2>;
  players.isFetching && <h2>Loading...</h2>;

  return (
    <div className="walletCreate__wrapper">
      <div className="walletCreate">
        <div className="walletCreate__title">
          <IoWallet />
          <h2>Criar um novo registro de carteira.</h2>
        </div>
        <form className="walletForm">
          <div className="walletForm__item">
            <label htmlFor="">Indústria:</label>
            <select
              required
              name=""
              id=""
              onChange={(e) => setIdIndustria(e.target.value)}
            >
              <option value="-1">Selecione</option>
              {industrias.data?.data?.values.map((ind) => (
                <option key={ind.id} value={ind.id}>
                  {ind.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="walletForm__item">
            <label htmlFor="">Player:</label>
            <select
              required
              name=""
              id=""
              onChange={(e) => setIdPlayer(e.target.value)}
            >
              <option value="-1">Selecione</option>
              {players.data?.data?.map((player) => (
                <option key={player.id} value={player.id}>
                  {player.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="walletForm__item">
            <label htmlFor="password">Senha:</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength={100}
              name="password"
              type="text"
            />
          </div>
          <div className="walletForm__item">
            <label htmlFor="chaves">Palavras chave (incluir espaços):</label>
            <textarea
              value={keyWords}
              onChange={(e) => setKeyWords(e.target.value)}
              maxLength={254}
              name="chaves"
            />
          </div>
          <div className="walletForm__item">
            <label htmlFor="wallet_adress">Número da carteira:</label>
            <input
              value={walletAdress}
              onChange={(e) => setWalletAdress(e.target.value)}
              maxLength={200}
              name="wallet_adress"
              type="text"
            />
          </div>
          <button onClick={(e) => createWalletHandler(e)}>Registrar</button>
        </form>
      </div>
    </div>
  );
};

export default WalletCreate;
