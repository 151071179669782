import React, { useEffect, useState } from "react";
import "./ticket.scss";
import Ticket from "./tickets/Ticket";
import MainContentWrapper from "../contentWrapper/MainContentWrapper";
//react query
import axios from "axios";
import { useQuery } from "react-query";
import { ticket } from "../../api/routes";
//alert
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const TicketPage = () => {
  const [btnSelect, setBtnSelect] = useState("duvida");
  const [indToRender, setIndToRender] = useState();
  let industriasToRender = [];

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  const fetchTicketsHandler = () => {
    return axios.get(ticket, config);
  };

  const ticketDataResponse = useQuery("tickets", fetchTicketsHandler, {
    refetchInterval: 5000,
    keepPreviousData: true,
  });

  const verifyTicketsToRender = () => {
    industriasToRender = [];
    if (ticketDataResponse.isFetched) {
      if (localStorage.getItem("industria") !== "-1") {
        industriasToRender.push(
          ticketDataResponse.data?.data.filter(
            (t) =>
              t.id_industria.toString() ===
              localStorage.getItem("industria").toString()
          )
        );
      } else {
        industriasToRender.push(ticketDataResponse.data?.data.map((e) => e));
      }
      setIndToRender(industriasToRender);
    }
  };

  window.addEventListener("industria", () => {
    verifyTicketsToRender();
  });

  useEffect(() => {
    verifyTicketsToRender();
  }, [ticketDataResponse.isFetching]);

  const verifyIfEmptyHandler = () => {
    if (indToRender === undefined || indToRender[0] === undefined) {
      return;
    }
    if (
      indToRender[0].findIndex((e) => e.tipo === "duvida") === -1 &&
      btnSelect === "duvida"
    ) {
      return <h2 className="empty-ticket">Nenhum ticket encontrado!</h2>;
    }
    if (
      indToRender[0].findIndex((e) => e.tipo === "problema") === -1 &&
      btnSelect === "problema"
    ) {
      return <h2 className="empty-ticket">Nenhum ticket encontrado!</h2>;
    }
  };

  if (ticketDataResponse.isError)
    return <h2 className="empty-ticket">Erro ao carregar!</h2>;

  return (
    <MainContentWrapper>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <nav className="nav-ticket">
        <div className="buttons-top-ticket-wrapper">
          <button
            onClick={() => setBtnSelect("duvida")}
            className={`button-ticket-top ${
              btnSelect === "duvida" && "button-ticket-active"
            }`}
          >
            Dúvidas
          </button>
          <button
            onClick={() => setBtnSelect("problema")}
            className={`button-ticket-top ${
              btnSelect === "problema" && "button-ticket-active"
            }`}
          >
            Problemas
          </button>
        </div>
      </nav>
      <div className="tickets-wrapper">
        {verifyIfEmptyHandler()}
        {indToRender !== undefined &&
          indToRender[0] !== undefined &&
          indToRender[0].map((e) => (
            <Ticket key={e.id} ticketInfo={e} ticketType={btnSelect} />
          ))}
      </div>
    </MainContentWrapper>
  );
};

export default TicketPage;
