import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
//React-query
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
//components
import Login from "./login/Login";
import Ticket from "./ticket/TicketPage";
import Wallet from "./wallet/WalletPage";
import Report from "./report/ReportPage";
import NotFound from "./notFound/NotFoundPage";
import NavBar from "./navBar/NavBar";
import TokenRefresh from "../util/tokenRefresh/TokenRefresh";
import WalletCreate from "./wallet/crud/WalletCreate";
import Wallets from "./wallet/wallets/Wallets";
import ErrosLog from "./errorsLog/ErrosLog";

function App() {
  const queryClient = new QueryClient();
  const [isLogged, setIsLogged] = useState(
    localStorage.getItem("logged") === "true" ? true : false
  );

  useEffect(() => {
    // localStorage.setItem("tk", "");
    // localStorage.setItem("logged", false);
    // localStorage.setItem("industria", -1);
    localStorage.getItem("logged") === "true"
      ? setIsLogged(true)
      : setIsLogged(false);
  }, []);

  window.addEventListener("storage", () => {
    localStorage.getItem("logged") === "true"
      ? setIsLogged(true)
      : setIsLogged(false);
  });

  return (
    <QueryClientProvider client={queryClient}>
      {isLogged && <NavBar />}
      {isLogged && <TokenRefresh />}
      <Routes>
        {!isLogged && <Route path="/" element={<Login />} />}
        <Route path="ticket" element={isLogged ? <Ticket /> : <NotFound />} />
        <Route path="wallet" element={<Wallet />}>
          <Route path="create" element={<WalletCreate />} />
          <Route path="wallets" element={<Wallets />} />
        </Route>
        <Route
          path="errosLog"
          element={isLogged ? <ErrosLog /> : <NotFound />}
        />
        <Route path="report" element={isLogged ? <Report /> : <NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </QueryClientProvider>
  );
}

export default App;
