import React from "react";
import "./report.scss";
import MainContentWrapper from "../contentWrapper/MainContentWrapper";

const Report = () => {
  return (
    <MainContentWrapper>
      <h2 className="tbd">TBD</h2>
    </MainContentWrapper>
  );
};

export default Report;
