const ROUTE = process.env.REACT_APP_ROUTE_URL;
const ROUTENEURON = process.env.REACT_APP_ROUTE_NEURON_URL;

const config = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${localStorage.getItem("tk")}`,
  },
};

export const login = `${ROUTE}api/login`;
export const industria = `${ROUTENEURON}api/industria`;

export const ticket = `${ROUTE}api/ticket`;
export const emailTicket = `${ROUTE}api/ticket/email`;
export const closeTicket = `${ROUTE}api/ticket/finaliza`;
export const refreshToken = `${ROUTE}api/login/refresh`;

//walltes
export const getWallets = `${ROUTE}api/wallet`;
export const createWallet = `${ROUTE}api/wallet`;

//Players
export const getAllPlayers = `${ROUTENEURON}api/player/grupos`;

//Erros
export const getErrors = `${ROUTE}api/front-error-log`;
