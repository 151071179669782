import React, { useState } from "react";
import "./login.scss";
import logo from "../../img/LogoIcro.png";
import { useNavigate } from "react-router-dom";
//react query
import { useQuery } from "react-query";
import axios from "axios";
import { login } from "../../api/routes";

export const ROUTESPATHS = {
  1: "/ticket",
  2: "/wallet",
  3: "/report",
  4: "/errosLog",
};

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();

  const fetchLogin = () => {
    return axios
      .post(login, {
        usuario: username,
        senha: password,
      })
      .then((resp) => setLocalStoreHandler(resp))
      .catch(() => setIsError(true));
    // .catch((err) => console.log(err.response))
  };

  const loginRequest = useQuery("login", () => fetchLogin(), {
    enabled: false,
    retry: false,
  });

  const loginHandler = (e) => {
    e.preventDefault();
    loginRequest.refetch();
  };

  let btnActive = parseInt(localStorage.getItem("btnActiveNavBar")) || 1;

  const setLocalStoreHandler = (resp) => {
    localStorage.setItem("tk", resp.data.tk);
    localStorage.setItem("logged", true);
    localStorage.setItem("refreshTokenTime", new Date().getTime());
    localStorage.setItem("refreshLongerTokenTime", new Date().getTime());
    localStorage.setItem("refreshToken", resp.data.refreshTk);
    window.dispatchEvent(new Event("storage"));
    navigate(ROUTESPATHS[btnActive]);
  };

  return (
    <section className="login-wrapper">
      <div className="login">
        <img src={logo} alt="logo" />
        <form className={`login-form ${isError && "login-error"}`}>
          {isError && (
            <span className="login-error-alert">
              Login ou senha incorreto(s)!
            </span>
          )}
          <input
            type="text"
            placeholder="Login"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <input
            type="password"
            name=""
            id=""
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <button onClick={(e) => loginHandler(e)} type="submit">
            Entrar
          </button>
        </form>
      </div>
    </section>
  );
};

export default Login;
