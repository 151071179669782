import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./notFound.scss";

import { ROUTESPATHS } from "../login/Login";

const NotFound = () => {
  const [timer, setTimer] = useState(3);
  const navigate = useNavigate();

  let btnActive = parseInt(localStorage.getItem("btnActiveNavBar")) || 1;

  React.useEffect(() => {
    const timerCountdown = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    if (timer <= 0) {
      navigate(`${ROUTESPATHS[btnActive]}`);
    }

    return () => clearInterval(timerCountdown);
  }, [timer]);

  return (
    <div className="notFound">
      <h2>Nothing Here!</h2>
      <Link to={ROUTESPATHS[btnActive]}>Go back!</Link>
      <p style={{ textAlign: "center", color: "#eee" }}>
        Going back in: {timer}
      </p>
    </div>
  );
};

export default NotFound;
