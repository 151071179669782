import React from "react";
import "./errosLog.scss";
import MainContentWrapper from "../contentWrapper/MainContentWrapper";
import axios from "axios";
import { useQuery } from "react-query";
import { getErrors } from "../../api/routes";
import ErrorLogItem from "./ErrorLogItem";

const ErrosLog = () => {
  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  const errorsFetch = () => {
    return axios.get(getErrors, config);
  };

  const { data, isLoading, isFetched } = useQuery("errors", () =>
    errorsFetch()
  );

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <MainContentWrapper>
      {isFetched && (
        <div className="errorsWrapper">
          {data?.data.map((e) => (
            <ErrorLogItem key={e.id} error={e} />
          ))}
        </div>
      )}
    </MainContentWrapper>
  );
};

export default ErrosLog;
