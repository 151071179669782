import React, { useEffect, useState } from "react";
import "./ticketResolve.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { emailTicket, closeTicket } from "../../../api/routes";
import axios from "axios";
import { useMutation } from "react-query";
//alert
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const TicketResolve = ({ ticketInfo }) => {
  const type = ticketInfo.tipo;
  const [ticketContent, setTicketContent] = useState("");
  const notify = () => toast.success("Ticket resolvido!");
  const notifyError = () => toast.warn("Erro!");

  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("tk")}`,
    },
  };

  const changeShowReponseHandler = () => {
    localStorage.setItem("ticketContent", "");
    localStorage.setItem("responseShow", 0);
    window.dispatchEvent(new Event("responseShowChange"));
  };

  const updateEmailHandler = (emailToSendContent) => {
    return axios
      .patch(
        `${emailTicket}/${ticketInfo.id}`,
        { content: emailToSendContent },
        config
      )
      .then(notify)
      .catch(notifyError);
  };

  const mutationEmail = useMutation(() => updateEmailHandler(ticketContent));

  const onSubmitResponse = () => {
    localStorage.setItem("ticketContent", "");
    localStorage.setItem("responseShow", "");
    mutationEmail.mutate();
  };

  const closeTicketHandler = () => {
    axios
      .patch(`${closeTicket}/${ticketInfo.id}`, { finalizado: true }, config)
      .then(notify)
      .catch(notifyError);
  };

  const mutationCLoseTicket = useMutation(() => closeTicketHandler());

  const onSubmitCloseTicket = () => {
    mutationCLoseTicket.mutate();
    localStorage.setItem("ticketContent", "");
    localStorage.setItem("responseShow", "");
  };

  const content = [
    {
      type: "duvida",
      btn: "Responder",
      btn_refuse: "Cancelar",
      label: `Respondendo ticket número ${ticketInfo.id}:`,
      func: () => onSubmitResponse(),
    },
    {
      type: "problema",
      btn: "Confimar",
      btn_refuse: "Cancelar",
      label: `O problema do ticket número ${ticketInfo.id} foi resolvido?`,
      func: () => onSubmitCloseTicket(),
    },
  ];

  const changeTicketContentHandler = (e) => {
    setTicketContent(e.target.value);
    localStorage.setItem("ticketContent", e.target.value);
  };

  useEffect(() => {
    setTicketContent(localStorage.getItem("ticketContent"));
  }, []);

  return (
    <div className="ticket_resolve-wrapper">
      <div className="ticket_resolve-inner">
        <div className="ticket_resolve-top">
          <h3>{content.find((e) => e.type === type).label}</h3>
          <AiOutlineCloseCircle onClick={changeShowReponseHandler} />
        </div>
        <div className="ticket_resolve-bottom">
          {type === "duvida" && (
            <textarea
              placeholder="Responda aqui a pergunta."
              value={ticketContent}
              onChange={changeTicketContentHandler}
            ></textarea>
          )}
          <div className="ticket_resolve-bottom-buttons_wrapper">
            <button
              onClick={changeShowReponseHandler}
              className="ticket_resolve-btn_refuse"
            >
              {content.find((e) => e.type === type).btn_refuse}
            </button>
            <button onClick={content.find((e) => e.type === type).func}>
              {content.find((e) => e.type === type).btn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketResolve;
