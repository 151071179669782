import React, { useEffect } from "react";
//api
import { useMutation } from "react-query";
import { refreshToken } from "../../api/routes";
import axios from "axios";
//toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//react router
import { useNavigate } from "react-router";

const TokenRefresh = () => {
  const notify = () => toast.success("Faça o login novamente!");
  const navigate = useNavigate();

  const loginSuccess = (resp) => {
    localStorage.setItem("tk", resp.data.tk);
  };

  const mutation = useMutation((data) => {
    return axios
      .post(refreshToken, data)
      .then((resp) => {
        loginSuccess(resp);
      })
      .catch((err) => {
        if (err.response.status === 401) loginSuccess(err.response);
      });
  });

  const logOutHandler = () => {
    localStorage.setItem("logged", "false");
    localStorage.setItem("function", "");
    localStorage.setItem("tk", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("industria", -1);
    localStorage.setItem("refreshTokenTime", "");
    localStorage.setItem("refreshLongerTokenTime", "");
    window.dispatchEvent(new Event("storage"));
    notify();
    navigate("/");
  };

  //RefreshToken 8 hours and logout 20 days
  useEffect(() => {
    const refreshVerificationInterval = setInterval(() => {
      let timeDifferenceToken =
        new Date().getTime() -
        parseInt(localStorage.getItem("refreshTokenTime"));
      let timeDifferenceTokenExpiration =
        new Date().getTime() -
        parseInt(localStorage.getItem("refreshLongerTokenTime"));

      const refreshTokenInterval = 28800000;
      //DEBUG
      // const refreshTokenInterval = 2000;
      // console.log(
      //   new Date().getTime(),
      //   parseInt(localStorage.getItem("refreshTokenTime")),
      //   timeDifferenceToken,
      //   timeDifferenceToken > refreshTokenInterval,
      //   localStorage.getItem("tk")
      // );
      //END DEBUG
      const expirationRefreshToken = 1728000000;
      if (timeDifferenceToken > refreshTokenInterval) {
        mutation.mutate({ refresh_tk: localStorage.getItem("refreshToken") });
        localStorage.setItem("refreshTokenTime", new Date().getTime());
        // window.location.reload();
      }

      if (timeDifferenceTokenExpiration > expirationRefreshToken) {
        logOutHandler();
      }

      if (localStorage.getItem("refreshToken") === undefined) {
        logOutHandler();
      }
      //DEBUG
      // }, 200);
    }, 2500);

    return () => {
      clearInterval(refreshVerificationInterval);
    };
  }, []);

  return <></>;
};

export default TokenRefresh;
